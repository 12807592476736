import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

import face from "../../../../assets/images/footer/face.png";
import instagram from "../../../../assets/images/footer/Asset 10.png";
import linked from "../../../../assets/images/footer/linked.png";
import pinterest from "../../../../assets/images/footer/pinterest.png";
import youtube from "../../../../assets/images/footer/you.png";
import twitter from "../../../../assets/images/footer/twitter.png";
import logo from "../../../../assets/images/footer/logo.svg";
import emailIcon from '../../../../assets/images/contact/icons/email.svg'
import bg from "../../../../assets/images/footer/bg.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { accountsConfig } from "../../../../axiosConfig";
import AccoladesLogo from "../../../../assets/images/icons/accolades_logo.svg";


function Footer({ color }) {
  const dest = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    accountsConfig.get("destinations/get-destinations/").then((response) => {
      const { StatusCode, data } = response.data.app_data;

      if (StatusCode === 6000) {
        setDestinations(data);
      } else {
        setDestinations([]);
      }
    });
  }, []);

  return (
    <Container type={color}>
      <Wrapper>
        <ContentContainer>
          <Content>
            <Logo>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </Logo>
            <About>
              <Title>About Us</Title>
              <Para>
                Since 2012, Medcity International Overseas Corporation has been
                offering gamut of Professional Services to students who aspire
                to study overseas and proficient professionals who dream of
                working overseas.
              </Para>
            </About>
            <About>
              <Title>Contact Address</Title>
              <Para>
                Medcity International Overseas Corporation,
                <br /> Chettipeedika, Kannur - 4, Kerala, India.
              </Para>
              <Para className="email-section">
                <a href="mailto:info@mioc.in"><img className="email-img" src={emailIcon} alt="" /></a>
                <a className="email" href="mailto:info@mioc.in">info@mioc.in</a>
              </Para>
            </About>
            <SocialMedia>
              <a
                href="https://www.facebook.com/medcityoverseas/"
                target="_blank"
              >
                <img src={face} alt="icon" />
              </a>
              <a
                href="https://www.instagram.com/medcitystudyabroad/"
                target="_blank"
              >
                <img src={instagram} alt="icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCsT_VNlhRGO5m3ZzXM_EQAA"
                target="_blank"
              >
                <img src={youtube} alt="icon" />
              </a>
              <a href="https://twitter.com/uk_studyin" target="_blank">
                <img src={twitter} alt="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/medcity-study-abroad"
                target="_blank"
              >
                <img src={linked} alt="icon" />
              </a>
              <a
                href="https://in.pinterest.com/medcityoverseas/"
                target="_blank"
              >
                <img src={pinterest} alt="icon" />
              </a>
            </SocialMedia>
          </Content>
        </ContentContainer>
        <Destination>
          <TextWrap len={destinations?.length}>Destinations</TextWrap>
          <CoverDest>
            {destinations.map((item) => (
              <Items to={`/destination/${item?.id}`} key={item?.id}>
                Study in {item?.country_name}{" "}
              </Items>
            ))}
          </CoverDest>
        </Destination>
      </Wrapper>
      <Banner>
        <span>© 2024 Medcity International Overseas Corporation. All rights reserved.{" "}</span>
        <LogoLink target="_blank" href="https://accoladesintegrated.com/">
          Designed and Developed By
          <CompanyLogo src={AccoladesLogo} />
        </LogoLink>
      </Banner>
    </Container>
    
  );
}

export default Footer;

const Container = styled.div`
  padding: 80px 0 200px 0;
  background: url(${bg});
  background-color: ${({ type }) => (type == "other" ? "" : "#fff")};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  z-index: 2;
  @media all and (max-width: 1600px) {
    background-size: 140%;
  }
  @media all and (max-width: 1100px) {
    background-size: 170%;
  }
  @media all and (max-width: 980px) {
    padding: 0px 0 200px 0;
    background-size: 200%;
  }
  @media all and (max-width: 800px) {
    background-size: 250%;
  }
  @media all and (max-width: 768px) {
    padding: 0px 0 150px 0;
    background-size: 200%;
  }

  @media all and (max-width: 580px) {
    background-size: 420%;
  }
  @media (max-width: 454px) {
    background-size: 500%;
  }
`;
const Wrapper = styled.div``;
const MobileCoverDest = styled.div`
  /* display: none; */
  @media all and (max-width: 480px) {
    /* display: block; */
  }
`;
const Banner = styled.div`
  position: absolute;
  padding: 0 10px;
  bottom: 6px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    bottom: 3px;
    gap: 1px;
  }

  @media screen and (max-width: 580px) {
    text-align: center;
    /* background-color: rgba(0,0,0,.15); */
  }

  @media screen  and (max-width:380px){
    font-size: 12px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  background-color: #202730;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1440px) {
    width: 90%;
  }
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;
const Logo = styled.div`
  width: 20%;
  margin: auto 0;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 34%;
  }
  @media all and (max-width: 768px) {
    width: 40%;
  }
  @media all and (max-width: 480px) {
    width: 70%;
  }
`;
const About = styled.div`
  width: 25%;
  @media all and (max-width: 768px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const Title = styled.h4`
  color: #fff;
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`;
const Para = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #d0d0d0;

  &.email-section{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  .email-img{
    width: 16px;
  }
  .email{
    font-size: 14px;
    line-height: 22px;
    color: #d0d0d0;
  }
`;

const Destination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1440px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }

  @media all and (max-width: 1360px) {
    flex-direction: column;
  }
`;
const TextWrap = styled.div`
  color: #00b1c3;
  font-family: "ubuntu-medium";
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: #949494;
    width: 75vw;
    height: 1px;
    top: 8px;
    left: 110px;
    /* right: -20px; */
    display: ${(props) => (props.len > 7 ? "block" : "none")};

    @media all and (max-width: 1440px) {
      width: 1180px;
    }
    @media all and (max-width: 1360px) {
      display: none;
    }
  }

  @media all and (max-width: 1360px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
`;
const CoverDest = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  @media all and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media all and (max-width: 980px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media all and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media all and (max-width: 480px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
const Items = styled(Link)`
  width: 175px;
  cursor: pointer;
  color: #4e4e4e;
  height: 50px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  text-align: center;

  /* &:nth-child(7) {
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 00px;
      right: -10px;
      background-color: #949494;
      @media all and (max-width: 480px) {
        height: 30px;
      }
      @media all and (max-width: 400px) {
        right: -25px;
      }
    }
  }

  &:nth-child(14) {
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 00px;
      right: -10px;
      background-color: #949494;
    }
  } */

  /* &:nth-child(6),
  &:nth-child(9), */
  /* &:nth-child(12) {
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 00px;
      right: -1px;
      background-color: #949494;
    }
  } */

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 30px;
    right: -10px;
    background-color: #949494;
    @media all and (max-width: 400px) {
      right: -25px;
    }
  }

  &:hover {
    color: #00b1c3;
  }

  @media all and (max-width: 1440px) {
    width: 175px;
  }
  @media all and (max-width: 1360px) {
    width: 90% !important;
    font-size: 13px;
  }
  @media all and (max-width: 400px) {
    width: 55% !important;
    font-size: 13px;
    margin: 0 auto;
  }
`;
const SocialMedia = styled.div`
  width: 18%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
  a {
    transition: 0.4s ease;
    &:hover {
      transform: translateY(-10px);
      transition: 0.4s ease;
    }
  }
  @media all and (max-width: 980px) {
    width: 33%;
  }
  @media all and (max-width: 768px) {
    width: 50%;
    margin-top: 30px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  background: #202730;
  padding: 20px 0;
  margin-bottom: 20px;
`;

const CompanyLogo = styled.img`
  width: 135px;
  height: auto;
`;

const LogoLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: #ffffff;
  font-size: 12px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;
