import React, { useEffect, useState } from "react";
import { accountsConfig } from "../../../../../../axiosConfig";

//images
import checkSquare from "../../../../../../assets/images/studyAbroad/check-square_svgrepo.com.png";
import checked from "../../../../../../assets/images/studyAbroad/checked.png";
import styled from "styled-components";

export default function ThingsToCheck() {
  const [getThings, setThings] = useState([]);
  const [getSubTitles, setSubTitles] = useState([]);
  const [getTitleId, setTitleId] = useState("");
  const [render, setRender] = useState(false);
  const [isChecked, setChecked] = useState();
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    accountsConfig.get("study/list-sub-titles/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setSubTitles(data);
      } else {
        setSubTitles([]);
      }
    });
  }, []);

  //list things
  useEffect(() => {
    getTitleId &&
      accountsConfig.get(`study/list-things/${getTitleId}`).then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setThings(data);
        } else {
          setThings([]);
        }
      });
  }, [getTitleId, render]);

  //mark as read
  const markAsRead = (item) => {
    setRender(false);
    accountsConfig
      .post(
        `study/mark-as-read/${item.id}/`,
        {},
        {
          params: {
            is_active: "True",
          },
        }
      )
      .then((res) => {
        if (res.data.app_data.StatusCode == 6000) {
          setRender(true);
        } else {
          setRender(false);
        }
      });
  };

  //check items 
  const handleCheck = (id) => {
    if(checkedItems.includes(id)){
       setCheckedItems((prev)=> prev.filter((item)=> item.id !== id))
    }else{
       setCheckedItems((prev)=> [...prev,id])
    }
  }

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Things To Check</Title>
          <Desc>
            What to Pack for Studying Abroad and what not to. As crucial as
            research is, it is equally important to pack properly before
            leaving.
          </Desc>
          <Things>
            {getSubTitles.map((item, index) => (
              <Item
                key={index}
                className={
                  checkedItems.includes(item.id)
                    ? "active"
                    : getTitleId == item.id
                    ? "active"
                    : ""
                }
                onClick={() => {
                  setTitleId(item.id);
                }}
              >
                <Icon>
                  { checkedItems.includes(item.id) || getTitleId == item.id ? (
                    <img src={checked} alt="" />
                  ) : (
                    <img src={checkSquare} alt="" />
                  )}
                </Icon>
                <Span>{item.sub_title}</Span>
              </Item>
            ))}
          </Things>
          {getTitleId && (
            <Section>
              <Steps>
                <Step>
                  <ImageContainer>
                    <img src={getThings?.image} alt="" />
                  </ImageContainer>
                  <Right>
                    <CoverRight>
                      <Text>{getThings?.title} </Text>
                      <Description>{getThings?.description} </Description>
                    </CoverRight>
                    {checkedItems.includes(getTitleId) ? (
                      <Bars>
                        <Button className="checked">checked</Button>
                      </Bars>
                    ) : (
                      <Bars onClick={() => handleCheck(getTitleId)}>
                        <Button>Mark as checked</Button>
                      </Bars>
                    )}
                  </Right>
                </Step>
              </Steps>
            </Section>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 80px 0;
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.h1`
  color: var(--blue);
  text-align: center;
  margin-bottom: 10px;
`;
const Desc = styled.p`
  text-align: center;
`;
const Things = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "ubuntu-medium";
  padding: 8px 20px;
  border-radius: 4px;
  gap: 10px;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px #00000009;
  &.active {
    background-color: var(--blue);
    color: #fff;
  }

  color: #898989;
`;
const Icon = styled.div`
  width: 30px;
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    img{
    width: 20px;
    height: 20px;
    }
  }
`;
const Span = styled.div``;
const Section = styled.div`
  margin-top: 50px;
`;
const Steps = styled.div``;
const Step = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 57px 211px 0px #00000012;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 24%;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const CoverRight = styled.div``;
const Right = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Count = styled.h2`
  font-family: "Rubik Scribble";
  color: #00aec0;
  margin-bottom: 20px;
`;
const Text = styled.h3`
  color: #00aec0;
  margin-bottom: 10px;
`;
const Description = styled.p`
  line-height: 24px;
  color: #555555;
`;
const ButtonCover = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.div`
  color: var(--blue);
  font-size: 14px;
  border: 1px solid var(--blue);
  height: 40px;

  cursor: pointer;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &.checked {
    background-color: var(--blue);
    color: #fff;
    cursor: alias;
  }
`;
const Bars = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const Bar = styled.div`
  height: 10px;
  width: 34px;
  border-radius: 2px;
  background-color: #e2e2e2;
  transform: skewX(317deg);
  &.active {
    background-color: var(--blue);
  }
`;
