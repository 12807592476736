import React from "react";
import datanotfoundIcon from "../../../../../assets/icons/datanotfound.svg";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

function DataNotFound() {
    const navigate = useNavigate()
    return (
        <Container>

            <img src={datanotfoundIcon} alt="no-data" />
            <Heading>
                Course Not Available? We're Here to Help!
            </Heading>
            <Description>
                It seems the course or college you're
                looking for isn't listed. Connect with
                our team to explore alternatives or get
                personalized guidance. We're just a click
                away!
            </Description>
            <Button onClick={()=>navigate('/contact')}>
                Contact us
            </Button>
        </Container>
    )
}

export default DataNotFound

const Container = styled.div`
  width: 30%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 1440px) {
    width: 80%;
  }
`;

const Heading = styled.p`
    font-size: 20px;
    font-family: 'ubuntu-bold';
    opacity: 70%;
    padding: 20px 0px 10px 0px;
    text-align: center;
`
const Description = styled.p`
    text-align: center;
    font-family: 'ubuntu-regular';
    font-size: 14px;
    opacity: 50%;
    line-height: 21px;
`
const Button = styled.button`
    text-transform: capitalize;
    background-color: var(--headerColor);
    color: white;
    padding: 10px 20px;
    border: none;
    font-family: 'ubuntu-regular';
    font-size: 16px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
`