import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

import HeaderEdit from "../includes/HeaderEdit";
import phone from "../../../../assets/images/contact/icons/phone.svg";
import telephone from "../../../../assets/images/contact/icons/telephone.svg";
import location from "../../../../assets/images/contact/icons/location.svg";
import clock from "../../../../assets/images/contact/icons/clock.svg";
import emailIcon from "../../../../assets/images/contact/icons/email.svg";
import Footer from "../includes/Footer";
import { accountsConfig } from "../../../../axiosConfig";
import ButtonLoader from "./../../../general/loader/ButtonLoader";

function Contact() {
  const [branches, setbranches] = useState([]);
  const [loader, setloader] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    accountsConfig.get("general/list-our-branch/").then((res) => {
      const { data, StatusCode } = res.data.app_data;
      if (StatusCode === 6000) {
        setbranches(data);
      } else {
        setbranches([]);
      }
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    setloader(true);

    // submiting the form
    accountsConfig.post("study/contact-us/", formData).then((res) => {
      const { data, StatusCode } = res.data.app_data;
      setloader(false);

      if (StatusCode === 6000) {
        e.target.reset();
        showSuccessAlert();
      } else {
        showErrorAlert();
      }
    });
  };

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  return (
    <Section>
      <HeaderEdit backgroundCl={"#00AEC0"} />
      <Container>
        <Wrapper className="wrapper">
          <Info>
            <Left>
              <Title>Contact Us</Title>
              <Para>
              Thank you for your interest in Medcity International 
              Overseas Corporation. Whether you have a question,
               feedback, or want to explore opportunities, we're here 
               to help. Please feel free to reach out to us via email 
               at <a target="_blank" href="mailto:info@mioc.in">info@mioc.in</a> or by phone at <a href="tel:+919846341888">+91 98463 41888</a> or <a href="tel:+918943280333">+91 89432 80333</a>. 
               We look forward to hearing from you!
              </Para>
              <Icons>
                <IconBox>
                  <Icon>
                    <img src={phone} alt="" />
                  </Icon>
                  <Text className="point-cur">
                    <span>Mobile</span>
                    <a href="tel:+919645020503">+919645020503</a>
                    <a href="tel:+91894328033">+ 91894328033</a>
                  </Text>
                </IconBox>
                <IconBox>
                  <Icon>
                    <img src={emailIcon} alt="" />
                  </Icon>
                  <Text className="point-cur">
                    <span>Email</span><a href="mailto:info@mioc.in">info@mioc.in</a>
                  </Text>
                </IconBox>
                <IconBox>
                  <Icon>
                    <img src={location} alt="" />
                  </Icon>
                  <Text className="address">
                    <span>Contact Address</span>
                    Medcity International Overseas Corporation, Chettipeedika,
                    Kannur - 4, Kerala, India.
                  </Text>
                </IconBox>
                <IconBox>
                  <Icon>
                    <img src={clock} alt="" />
                  </Icon>
                  <Text>
                    <span>Working Hours</span>
                    Monday to Friday: 10am to 6pm <br />
                    Saturday: 10am to 4pm
                  </Text>
                </IconBox>
              </Icons>
            </Left>
            <Right>
              <FormDiv>
                <Form onSubmit={(e) => handleFormSubmit(e)} ref={formRef}>
                  <Heading>Send a message</Heading>
                  <InputsDiv>
                    <InputCover className="name">
                      <Label>Name</Label>
                      <Input
                        placeholder="Your name"
                        type="text"
                        name="name"
                        required
                      />
                    </InputCover>

                    <InputCover className="phone">
                      <Label>Phone Number</Label>
                      <Input
                        placeholder="Your number"
                        type="number"
                        name="phone"
                        required
                      />
                    </InputCover>

                    <InputCover className="email">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        placeholder="Email"
                        type="email"
                        required
                        name="email"
                      />
                    </InputCover>

                    <InputCover className="message">
                      <Label>Message</Label>
                      <TextFeild
                        placeholder="Message"
                        cols={2}
                        rows={5}
                        name="message"
                        required
                      />
                    </InputCover>
                  </InputsDiv>
                  {loader ? (
                    <SubmiBtn type="button">
                      <ButtonLoader />
                    </SubmiBtn>
                  ) : (
                    <SubmiBtn>Submit</SubmiBtn>
                  )}
                </Form>
              </FormDiv>
            </Right>
          </Info>
          <Branches>
            {branches?.map((obj, indx) => (
              <Branch>
                <BranchName>{obj?.name}</BranchName>
                <Loc>{obj?.address}</Loc>
                <Num>
                  <a href={`tel:${obj?.tel}`}>{obj?.tel}</a>{obj?.tel && obj.mobile && ','} <a href={`tel:${obj?.mobile}`}>{obj?.mobile}</a>
                </Num>
              </Branch>
            ))}
          </Branches>
        </Wrapper>
        <Map>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7807.53926349113!2d75.351648!3d11.921114!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba43d3383e2cdb9%3A0xc57514444e22d39b!2sMedcity%20International%20Overseas%20Corporation%20%7C%20Best%20Study%20Abroad%20Consultants!5e0!3m2!1sen!2sin!4v1704262864424!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Map>
      </Container>
      <Footer />
    </Section>
  );
};

export default Contact;

const Section = styled.div`
  padding-top: 100px;

  @media (max-width: 1425px) {
    padding-top: 90px;
  }

  @media (max-width: 1200px) {
    padding-top: 80px;
  }

  @media (max-width: 950px) {
    padding-top: 73px;
  }
`;

const Container = styled.div`
  background-color: #f8f8f8;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media screen and (max-width: 580px) {
    padding-top: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const Info = styled.div`
  display: flex;
  gap: 120px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 80px;
  }
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Right = styled.div`
  width: 50%;

  @media screen and (max-width: 1200px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  color: #19a2bb;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 580px) {
    font-size: 32px;
  }
`;

const Para = styled.p`
  color: #2b2b2b;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;

  @media screen and (max-width: 580px) {
    font-size: 15px;
  }
  a{
    color: #19a2bb;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
`;

const IconBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 435px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 15px;
  font-style: normal;
  line-height: 22px;

  &.point-cur {
    cursor: pointer;
  }

  &.address {
    width: 28ch;

    @media screen and (max-width: 520px) {
      width: 20ch;
    }
    @media screen and (max-width: 435px) {
      width: 100%;
      text-align: center;
    }
  }

  span {
    color: #000;
    text-align: justify;
    font-size: 16px;
    line-height: 130%; /* 20.8px */
    font-family: "ubuntu-medium";

    @media screen and (max-width: 435px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 8px;
  }
  @media screen and (max-width: 580px) {
    font-size: 15px;
  }

  @media screen and (max-width: 435px) {
    text-align: center;
  }
  a{
    color: #19a2bb;
  }
`;

const Icon = styled.div`
  @media screen and (max-width: 435px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Form = styled.form`
  padding: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 80px;
  @media screen and (max-width: 580px) {
    padding: 12px;
  }
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 25px;
  grid-template-areas:
    " name name"
    "phone email"
    "message message";

  @media screen and (max-width: 580px) {
    grid-template-areas:
      "name name"
      "phone phone"
      "email email"
      "message message";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cbcbcb;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.message {
    grid-area: message;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #5e6366;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const SubmiBtn = styled.button`
  width: 40%;
  background-color: #00aec0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 580px) {
    width: 70%;
  }
`;

const TextFeild = styled.textarea`
  border: none;
  outline: none;
  padding: 0;
  resize: vertical;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Branches = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content:space-between;
`;

const Branch = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 28%;

  @media screen and (max-width: 1250px) {
    width: 40%;
    margin: 0 auto;
  }

  @media screen and (max-width: 625px) {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const BranchName = styled.h6`
  color: #147d92;
  font-size: 22px;
  letter-spacing: -0.44px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #d9d9d9;
`;

const Loc = styled.div`
  color: #2b2b2b;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  line-height: 130%; /* 20.8px */
`;

const Num = styled.div`
  color: #19a2bb;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 130%; /* 20.8px */
  a{
    color: #19a2bb;
  }
`;

const Map = styled.div`
  height: 100vh;
`;
