import React from "react";

//imports
import { introduction_migrate } from "../../../../../general/commonArrays";
import SpaceText from "./titles/SpaceText";

//packages
import styled from "styled-components";

//images
import yellowpoint from "../../../../../../assets/images/migrate/yellowpoint.png";
import map from "../../../../../../assets/images/migrate/mapwithflight.svg";

export default function Introduction() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Migrate</Title>
          <Details>
            <TextSection>
              <TitleDescription>
              Why Migrate to Canada?
              </TitleDescription>
              <DetailDescription>
                 Migrate to Canada for a high quality of life, 
                excellent healthcare and education, diverse culture, strong 
                economy, and stunning natural landscapes. Enjoy a welcoming environment, 
                progressive policies, and abundant opportunities for personal and professional 
                growth in one of the world's most livable countries.
              </DetailDescription>
              <SubTitle>Steps involved in Canada PR:</SubTitle>
              <Points>
                {introduction_migrate.map((obj, index) => (
                  <CoverPoint key={index}>
                    <ICon>
                      <img src={yellowpoint} alt="circle" />
                    </ICon>
                    <CoverTextPoint>
                      <TextPoint>{obj.title} <span>:</span></TextPoint>
                      <Span>{obj.sub_title} </Span>
                    </CoverTextPoint>
                  </CoverPoint>
                ))}
              </Points>
            </TextSection>
            <MapContainer>
              <ImageContainer>
                <img src={map} alt="map" />
              </ImageContainer>
            </MapContainer>
          </Details>
          {/* <Pr>
            <SubTitle>Canada PR (Permanent Residency):</SubTitle>
            <DetailDescription>
              <SpaceText /> Canada PR offers permanent residency, conferring
              most citizen privileges except voting rights. Governed by
              Immigration, Refugees and Citizenship Canada (IRCC), applicants
              are chosen by the IRCC board.
            </DetailDescription>
          </Pr>
          <Pr>
            <SubTitle>Pathways to Canada PR:</SubTitle>
            <Points className="flex">
              {introduction_migrate.map((obj, index) => (
                <CoverPoint key={index} className="flex">
                  <ICon>
                    <img src={yellowpoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.sub_title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
          </Pr> */}
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #00aec0;
  padding-bottom: 80px;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  padding-top: 150px;
  color: #fff;
  @media (max-width: 480px) {
    padding-top: 100px;
  }
`;
const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 50px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    font-size: 40px;
  }
`;
const Details = styled.div`
  display: flex;
  margin: 50px 0;
  @media (max-width: 1280px) {
    flex-direction: column-reverse;
    margin: 0;
  }
`;
const TextSection = styled.div`
  width: 60%;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;
const TitleDescription = styled.h1`
  color: #fff;
  letter-spacing: 1px;
  font-size: 48px;
  /* text-align: center; */
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 32px;
  }
`;
const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      display: block;
    }
  }
  margin-bottom: 20px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  /* align-items: center; */
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
    @media (max-width: 480px) {
      width: 100%;
      line-height: 26px;
    }
  }
  @media (max-width: 640px) {
    align-items: flex-start;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  @media (max-width: 640px){
    flex-direction: column;
  }
`;
const TextPoint = styled.h4`
  font-size: 18px;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 640px){
    width: auto;
    justify-content: start;
  }
`;
const Span = styled.p`
  width: 80%;
  @media (max-width: 640px){
    width: auto;
  }
`;
const MapContainer = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 1280px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const ImageContainer = styled.div`
  position: absolute;
  right: -210px;
  top: -70px;
  img {
    width: 100%;
    display: block;
  }
  @media (max-width: 1280px) {
    position: unset;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
`;
const Pr = styled.div``;
const SubTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 20px;
`;

const DetailDescription = styled.p`
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 20px;
`;
