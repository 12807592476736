import con1 from "../../assets/images/countries/cont.jpg";
import con2 from "../../assets/images/countries/canada.jpg";
import con3 from "../../assets/images/countries/ireland.jpg";
import con4 from "../../assets/images/countries/denmark.jpg";
import con5 from "../../assets/images/countries/usa.jpg";
import con6 from "../../assets/images/countries/germany.jpg";
import con7 from "../../assets/images/countries/uk.jpg";
import con8 from "../../assets/images/countries/austria-ceu.webp";

// institutes images
import uni1 from "../../assets/images/institutes/7.jpg";
import uni2 from "../../assets/images/institutes/8.jpg";
import uni3 from "../../assets/images/institutes/9.jpg";
import uni4 from "../../assets/images/institutes/10.jpg";
import uni5 from "../../assets/images/institutes/11.jpg";
import uni6 from "../../assets/images/institutes/12.jpg";
import uni7 from "../../assets/images/institutes/13.jpg";
import uni8 from "../../assets/images/institutes/14.jpg";
import uni9 from "../../assets/images/institutes/15.jpg";
import uni10 from "../../assets/images/institutes/16.jpg";
import uni11 from "../../assets/images/institutes/17.jpg";
import uni12 from "../../assets/images/institutes/18.jpg";
import uni13 from "../../assets/images/institutes/19.jpg";
import uni14 from "../../assets/images/institutes/20.jpg";
import uni15 from "../../assets/images/institutes/22.jpg";
import uni16 from "../../assets/images/institutes/23.jpg";
import uni17 from "../../assets/images/institutes/24.jpg";
import uni18 from "../../assets/images/institutes/25.jpg";
import uni19 from "../../assets/images/institutes/26.jpg";
import uni20 from "../../assets/images/institutes/27.jpg";
import uni21 from "../../assets/images/institutes/28.jpg";

// intitution banner
import banner1 from "../../assets/images/institutes/university1.png";
import banner2 from "../../assets/images/institutes/university2.png";
import banner4 from "../../assets/images/institutes/university3.png";
import banner5 from "../../assets/images/institutes/university4.png";
import banner3 from "../../assets/images/institutes/university5.png";
import banner6 from "../../assets/images/institutes/university16.png";

// news and events
import event1 from "../../assets/images/events/News 01.jpeg";
import event2 from "../../assets/images/events/News 02.jpeg";
import event3 from "../../assets/images/events/News 03.jpg";
import event4 from "../../assets/images/events/News 04.jpg";
import event5 from "../../assets/images/events/News 01.jpeg";

//courses
import engine from "../../assets/images/course/engineering.jpg";
import arts from "../../assets/images/course/arts.jpg";
import legal from "../../assets/images/course/law.jpg";
import comp from "../../assets/images/course/computer.jpg";
import heal from "../../assets/images/course/health.jpg";
import hos from "../../assets/images/course/hospitality.jpg";

//dream country
import d_country1 from "../../assets/images/work/c1.jpg";
import d_country2 from "../../assets/images/work/c2.jpg";
import d_country3 from "../../assets/images/work/c3.jpg";
import d_country4 from "../../assets/images/work/c4.jpg";
import d_country5 from "../../assets/images/work/c5.jpg";
import d_country6 from "../../assets/images/work/c6.jpg";

import canadian from "../../assets/images/work/canadian.jpg";
import austria from "../../assets/images/work/austrailia.jpg";

import lens from "../../assets/images/HomePage/gifs/1.gif";
import calculator from "../../assets/images/HomePage/gifs/2.gif";


// course images

import img from '../../assets/images/HomePage/Couse-imgs/1.png';
import img1 from '../../assets/images/HomePage/Couse-imgs/2.png';
import img2 from '../../assets/images/HomePage/Couse-imgs/3.png';
import img3 from '../../assets/images/HomePage/Couse-imgs/4.png';
import img4 from '../../assets/images/HomePage/Couse-imgs/5.png';
import img5 from '../../assets/images/HomePage/Couse-imgs/6.png';




export const tab_names = [
  {
    id: 1,
    title: "Engineering",
  },
  {
    id: 2,
    title: "Arts",
  },
  {
    id: 3,
    title: "Health & Science",
  },
  {
    id: 4,
    title: "Law & Legal Studies",
  },
  {
    id: 5,
    title: "Computer Science",
  },
  {
    id: 6,
    title: "Hospitality & Tourism Management",
  },
  {
    id: 7,
    title: "Business & Management",
  },
];

export const engineering = [
  {
    id: 1,
    title: "Engineering Course",
  },
  {
    id: 3,
    title: "Engineering Course",
  },
  {
    id: 4,
    title: "Engineering Course",
  },
  {
    id: 2,
    title: "Engineering Course",
  },
  {
    id: 5,
    title: "Engineering Course",
  },
  {
    id: 6,
    title: "Engineering Course",
  },
  {
    id: 17,
    title: "Engineering Course",
  },
  {
    id: 18,
    title: "Engineering Course",
  },
  {
    id: 19,
    title: "Engineering Course",
  },
  {
    id: 10,
    title: "Engineering Course",
  },
  {
    id: 11,
    title: "Engineering Course",
  },
  {
    id: 12,
    title: "Engineering Course",
  },
];

export const Arts = [
  {
    id: 1,
    title: "Arts Course",
  },
  {
    id: 3,
    title: "Arts Course",
  },
  {
    id: 4,
    title: "Arts Course",
  },
  {
    id: 2,
    title: "Arts Course",
  },
  {
    id: 5,
    title: "Arts Course",
  },
  {
    id: 6,
    title: "Arts Course",
  },
  {
    id: 17,
    title: "Arts Course",
  },
  {
    id: 18,
    title: "Arts Course",
  },
  {
    id: 19,
    title: "Arts Course",
  },
  {
    id: 10,
    title: "Arts Course",
  },
  {
    id: 11,
    title: "Arts Course",
  },
  {
    id: 12,
    title: "Arts Course",
  },
];

export const health = [
  {
    id: 1,
    title: "Health & Science course",
  },
  {
    id: 3,
    title: "Health & Science course",
  },
  {
    id: 4,
    title: "Health & Science course",
  },
  {
    id: 2,
    title: "Health & Science course",
  },
  {
    id: 5,
    title: "Health & Science course",
  },
  {
    id: 6,
    title: "Health & Science course",
  },
  {
    id: 17,
    title: "Health & Science course",
  },
  {
    id: 18,
    title: "Health & Science course",
  },
  {
    id: 19,
    title: "Health & Science course",
  },
  {
    id: 10,
    title: "Health & Science course",
  },
  {
    id: 11,
    title: "Health & Science course",
  },
  {
    id: 12,
    title: "Health & Science course",
  },
];

export const law = [
  {
    id: 1,
    title: "Law and Legal Courses",
  },
  {
    id: 3,
    title: "Law and Legal Courses",
  },
  {
    id: 4,
    title: "Law and Legal Courses",
  },
  {
    id: 2,
    title: "Law and Legal Courses",
  },
  {
    id: 5,
    title: "Law and Legal Courses",
  },
  {
    id: 6,
    title: "Law and Legal Courses",
  },
  {
    id: 17,
    title: "Law and Legal Courses",
  },
  {
    id: 18,
    title: "Law and Legal Courses",
  },
  {
    id: 19,
    title: "Law and Legal Courses",
  },
  {
    id: 10,
    title: "Law and Legal Courses",
  },
  {
    id: 11,
    title: "Law and Legal Courses",
  },
  {
    id: 12,
    title: "Law and Legal Courses",
  },
];

export const computer = [
  {
    id: 1,
    title: "Computer Science Course",
  },
  {
    id: 3,
    title: "Computer Science Course",
  },
  {
    id: 4,
    title: "Computer Science Course",
  },
  {
    id: 2,
    title: "Computer Science Course",
  },
  {
    id: 5,
    title: "Computer Science Course",
  },
  {
    id: 6,
    title: "Computer Science Course",
  },
  {
    id: 17,
    title: "Computer Science Course",
  },
  {
    id: 18,
    title: "Computer Science Course",
  },
  {
    id: 19,
    title: "Computer Science Course",
  },
  {
    id: 10,
    title: "Computer Science Course",
  },
  {
    id: 11,
    title: "Computer Science Course",
  },
  {
    id: 12,
    title: "Computer Science Course",
  },
];

export const hospitality = [
  {
    id: 1,
    title: "Hospitality course",
  },
  {
    id: 3,
    title: "Hospitality course",
  },
  {
    id: 4,
    title: "Hospitality course",
  },
  {
    id: 2,
    title: "Hospitality course",
  },
  {
    id: 5,
    title: "Tourism Management",
  },
  {
    id: 6,
    title: "Hospitality course",
  },
  {
    id: 17,
    title: "Tourism Management",
  },
  {
    id: 18,
    title: "Hospitality course",
  },
  {
    id: 19,
    title: "Hospitality course",
  },
  {
    id: 10,
    title: "Tourism Management",
  },
  {
    id: 11,
    title: "Hospitality course",
  },
  {
    id: 12,
    title: "Hospitality course",
  },
];

export const business = [
  {
    id: 1,
    title: "Business and Management Course",
  },
  {
    id: 3,
    title: "Business and Management Course",
  },
  {
    id: 4,
    title: "Business and Management Course",
  },
  {
    id: 2,
    title: "Business and Management Course",
  },
  {
    id: 5,
    title: "Management Course",
  },
  {
    id: 6,
    title: "Business and Management Course",
  },
  {
    id: 17,
    title: "Management Course",
  },
  {
    id: 18,
    title: "Business and Management Course",
  },
  {
    id: 19,
    title: "Business and Management Course",
  },
  {
    id: 10,
    title: "Management Course",
  },
  {
    id: 11,
    title: "Business and Management Course",
  },
  {
    id: 12,
    title: "Business and Management Course",
  },
];

export const sliderImages = [
  {
    alt: "Img 1",
    id: 1,
    image: con1,
    title: "UK",
    top: 422,
    left: 91,
  },
  {
    alt: "Img 2",
    id: 2,
    image: con2,
    title: "USA",
    top: 162,
    left: 154,
  },
  {
    alt: "Img 3",
    id: 3,
    image: con3,
    title: "CANADA",
    top: 95,
    left: 45,
  },
  {
    alt: "Img 4",
    id: 4,
    image: con4,
    title: "AUSTRALIA",
    top: 795,
    left: 337,
  },
  {
    alt: "Img 5",
    id: 5,
    image: con5,
    title: "NEW ZEALAND",
    top: 900,
    left: 375,
  },
  {
    alt: "Img 6",
    id: 6,
    image: con6,
    title: "GERMANY",
    top: 460,
    left: 105,
  },
  {
    alt: "Img 7",
    id: 7,
    image: con7,
    title: "IRELAND",
    top: 408,
    left: 94,
  },
  {
    alt: "Img 8",
    id: 8,
    image: con8,
    title: "SINGAPORE",
    top: 50,
    left: 60,
  },
  {
    alt: "Img 9",
    id: 9,
    image: con8,
    title: "SPAIN",
    top: 50,
    left: 60,
  },
];

export const institutesImages = [
  {
    id: 1,
    title: "Uclan",
    image: uni1,
    banner: banner1,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 2,
    title: "University od Sunderland",
    image: uni2,
    banner: banner2,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 3,
    title: "Cambrian",
    image: uni3,
    banner: banner3,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 4,
    title: "University of WestMinster",
    image: uni4,
    banner: banner4,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 5,
    title: "University of Bedforshire",
    image: uni5,
    banner: banner5,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 6,
    title: "Centennial College",
    image: uni6,
    banner: banner6,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 7,
    title: "Humber",
    image: uni7,
    banner: banner1,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 8,
    title: "Edinburgh Napier",
    image: uni8,
    banner: banner2,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 9,
    title: "George Brown",
    image: uni9,
    banner: banner5,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 10,
    title: "University of South Wales",
    image: uni10,
    banner: banner6,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 11,
    title: "Uclan",
    image: uni11,
    banner: banner3,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 12,
    title: "University of WestMinster",
    image: uni12,
    banner: banner1,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 13,
    title: "Cambrian",
    image: uni13,
    banner: banner2,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 14,
    title: "University of Bedforshire",
    image: uni14,
    banner: banner3,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 15,
    title: "Centennial College",
    image: uni15,
    banner: banner4,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 16,
    title: "Edinburgh Napier",
    image: uni16,
    banner: banner5,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 17,
    title: "George Brown",
    image: uni17,
    banner: banner6,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 18,
    title: "University of South Wales",
    image: uni18,
    banner: banner5,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 19,
    title: "Uclan",
    image: uni19,
    banner: banner1,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 20,
    title: "Cambrian",
    image: uni20,
    banner: banner2,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
  {
    id: 21,
    title: "University of Bedforshire",
    image: uni21,
    banner: banner5,
    description:
      "Known for its strong emphasis on interdisciplinary education, Stanford University combines academic rigor with entrepreneurial spirit, fostering a vibrant campus culture that encourages students to explore diverse interests and make significant contributions to society.",
  },
];

export const newevents = [
  {
    id: 1,
    title: "Study in Denmark",
    image: event1,
    detail: [
      {
        id: 11,
        subtitle: "No age limit",
      },
      {
        id: 2,
        subtitle: "Stay-back",
      },
      {
        id: 3,
        subtitle: "Job opportunities",
      },
    ],
  },
  {
    id: 2,
    title: "Study in Finland",
    image: event2,
    detail: [
      {
        id: 131,
        subtitle: "Study Without IELTS.",
      },
    ],
  },
  {
    id: 3,
    title: "Study in Canada - Webinar",
    image: event3,
    detail: [
      {
        id: 131,
        subtitle:
          "Thinking of studying in CANADA after your +2 ? Get all your questions answered LIVE!",
      },
    ],
  },
  {
    id: 5,
    title: "Study in Germany",
    image: event4,
    detail: [
      {
        id: 131,
        subtitle: "Study in Germany",
      },
    ],
  },
  {
    id: 45,
    title: "How to Avoid Canada Visa Rejection",
    image: event5,
    detail: [
      {
        id: 131,
        subtitle:
          "Attend our free webinar to get answers to all your doubts on study abroad.",
      },
    ],
  },
];

export const destinations = [
  {
    id: 1,
    title: "Study in UK",
  },
  {
    id: 2,
    title: "Study in USA",
  },
  {
    id: 3,
    title: "Study in Canada",
  },
  {
    id: 4,
    title: "Study in Australia",
  },
  {
    id: 5,
    title: "Study in New Zealand",
  },
  {
    id: 6,
    title: "Study in Germany",
  },
  {
    id: 7,
    title: "Study in Ireland",
  },
  {
    id: 8,
    title: "Study in Singapore",
  },
  {
    id: 9,
    title: "Study in Malaysia",
  },
  {
    id: 10,
    title: "Study in Sweden",
  },
  {
    id: 11,
    title: " Study in Spain",
  },
  {
    id: 12,
    title: "Study in Switzerland",
  },
  {
    id: 13,
    title: "Study in France",
  },
  {
    id: 14,
    title: "Study in Italy",
  },
];

export const all_courses = [
  {
    id: 1,
    title: "Engineering",
    icon: engine,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",

    streams: [
      {
        id: "12",
        title: "Aeronautical Engineering",
      },
      {
        id: "132",
        title: "Architecture",
      },
      {
        id: "142",
        title: "Aviation Technology",
      },
      {
        id: "152",
        title: "Biomedical Engineering",
      },
      {
        id: "162",
        title: "Civil Engineering",
      },
      {
        id: "172",
        title: "Computer Engineering",
      },
    ],
  },
  {
    id: 134,
    title: "Arts",
    icon: arts,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",
    streams: [
      {
        id: "1122",
        title: "Film Production",
      },
      {
        id: "12332",
        title: "Theatre Arts",
      },
      {
        id: "14542",
        title: "Literature",
      },
      {
        id: "154532",
        title: "Linguistics",
      },
      {
        id: "13462",
        title: "Translation Studies",
      },
      {
        id: "we172",
        title: "Humanities",
      },
      {
        id: "we172",
        title: "Mass Communication &amp; Media Studies",
      },
    ],
  },

  {
    id: 1565,
    title: "Health & Science",
    icon: heal,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",
    streams: [
      {
        id: "1422",
        title: "Clinical Sciences",
      },
      {
        id: "34332",
        title: "Genetics",
      },
      {
        id: "15442",
        title: "Food Science",
      },
      {
        id: "1632",
        title: "Dentistry",
      },
      {
        id: "13487",
        title: "Health Care",
      },
      {
        id: "we1565",
        title: "Medical Science",
      },
      {
        id: "we1565",
        title: "Nursing",
      },
      {
        id: "we1565",
        title: "Occupational Therapy",
      },
      {
        id: "we1565",
        title: "Pharmaceutical Science",
      },
      {
        id: "we1565",
        title: "Paramedical",
      },
      {
        id: "we1565",
        title: "Respiratory Care",
      },
      {
        id: "we1565",
        title: "Biology Science",
      },
    ],
  },
  {
    id: 1677,
    title: "Law & Legal Studies",
    icon: legal,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",

    streams: [
      {
        id: "1442",
        title: "International Law",
      },
      {
        id: "3432",
        title: "Business Law",
      },
      {
        id: "1442",
        title: "Criminal Law",
      },
      {
        id: "1332",
        title: "European Law",
      },
      {
        id: "13587",
        title: "Patent &amp; Intellectual Property Law",
      },
      {
        id: "1565",
        title: "Civil &amp; Private Law",
      },
      {
        id: "13587",
        title: "Human Rights Law",
      },
      {
        id: "1565",
        title: "Maritime Law",
      },
      {
        id: "13587",
        title: "International Tax Law",
      },
      {
        id: "1565",
        title: "Banking Law ",
      },
    ],
  },
  {
    id: 123234,
    title: "Computer Science",
    icon: comp,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",
    streams: [
      {
        id: "14242",
        title: "Applied Computer Science",
      },
      {
        id: "34532",
        title: "Coding",
      },
      {
        id: "14242",
        title: "Computer Networking",
      },
      {
        id: "13362",
        title: "Computing",
      },
      {
        id: "135787",
        title: "Microsoft Certification",
      },
      {
        id: "15865",
        title: "Programming Languages",
      },
      {
        id: "15865",
        title: "Web Design Technology",
      },
      {
        id: "15865",
        title: "Data Analytics",
      },
      {
        id: "15865",
        title: "Web – Technology",
      },
    ],
  },
  {
    id: 1797867565,
    title: "Hospitality & Tourism Management ",
    icon: hos,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non rhoncus orci. Ut consectetur, nisl et faucibusauctor, justo sem consequat lectus, sed volutpat metuspurus non quam. Nulla sed ornare lacus.",
    streams: [
      {
        id: "4242",
        title: "International Hospitality",
      },
      {
        id: "3532",
        title: "Hotel Management",
      },
      {
        id: "1422",
        title: "International Event Management",
      },
      {
        id: "1462",
        title: "Catering Technology",
      },
      {
        id: "1357",
        title: "Luxury Hospitality Management",
      },
      {
        id: "1585",
        title: "Tourism and Hospitality Management",
      },
    ],
  },
  // {
  //   id: 13567543,
  //   title: "Business & Management",
  //   icon: bus,
  //   streams: [
  //     {
  //       id: "4242",
  //       title: "Business",
  //     },
  //     {
  //       id: "3552",
  //       title: "Business",
  //     },
  //     {
  //       id: "1622",
  //       title: "Business",
  //     },
  //     {
  //       id: "1472",
  //       title: "Business",
  //     },
  //     {
  //       id: "1307",
  //       title: "Business",
  //     },
  //     {
  //       id: "1505",
  //       title: "Business",
  //     },
  //   ],
  // },
];

export const countries = [
  {
    id: "1",
    title: "UK",
  },
  {
    id: "2",
    title: "USA",
  },

  {
    id: "4",
    title: "Germany",
  },
  {
    id: "5",
    title: "France",
  },
  {
    id: "6",
    title: "Australia",
  },
];
export const degree_type = [
  {
    id: "MC_1",
    title: "Masters",
  },
  {
    id: "MC_2",
    title: "Bachelors",
  },
  {
    id: "MC_3",
    title: "Diploma",
  },
  {
    id: "MC_3",
    title: "Other",
  },
];

export const filtered_course_listing = [
  {
    id: "MD_1",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
  {
    id: "MD_2",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
  {
    id: "MD_3",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
  {
    id: "MD_4",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
  {
    id: "MD_5",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
  {
    id: "MD_6",
    course_name: "Course Name",
    university_name: "University name",
    country: "USA",
    online: "6 Months",
    on_campus: "12 Months",
    in_take: "6 Months",
    online: "Feb, Jul",
  },
];

export const filtered = [
  {
    id: 1,
    title: "Masters",
  },
  {
    id: 2,
    title: "Diploma",
  },
  {
    id: 3,
    title: "2 years",
  },
  {
    id: 4,
    title: "February",
  },
];


export const programes_list = [
`Online Programmes/ Distance Learning`,
`English Language Program`,
'Twinning Programmes (PG)',
'Twinning Programmes (UG)',
"Pathway Programs",
"Short Term Programs",
'Foundation',
"Phd",
"UG+PG (Accelerated) Degree",
"PG",
"PG Diploma/ Certificate",
"UG",
"UG Diploma/ Certificate",
"Associate Degree",
"High School (11th - 12th)"
]

export const disipline_area_list =[
   'Data Science and Analytics',
   "Cytotechnology",
   "Computing",
   "Computer/Game Programming",
   "Computer Technology",
   "Computer Science, Software Development",
   "Business Analysis, IT Business Analytics",
   "Artificial Intelligence",
   "Cytotechnology",
   "Computing",
   "Computer/Game Programming",
   "Computer Technology",
   "Computer Science, Software Development",
   "Business Analysis, IT Business Analytics",
   "Artificial Intelligence"

]

export const dream_country = [
  {
    id: "dsf",
    image: d_country1,
    title: "Canada Work Visa",
  },
  {
    id: "d23sf",
    image: d_country2,
    title: "Australia Work Visa",
  },
  {
    id: "dsref",
    image: d_country3,
    title: "UK Work Visa",
  },
  {
    id: "d454sf",
    image: d_country4,
    title: "Germany Work Visa",
  },
  {
    id: "ds1f",
    image: d_country5,
    title: "New Zealand Work Visa",
  },
  {
    id: "dsdfg23f",
    image: d_country6,
    title: "Ireland Work Visa",
  },
];
export const skilled_immigration = [
  {
    id: "ksdhfojs",
    image: canadian,
    title: "Canadian Skilled Immigration",
    description:
      "Canada's Skilled Immigration program is designed to attract individuals with the skills and experience needed to contribute to the Canadian economy. Through programs like Express Entry, skilled workers can apply for Permanent Residency based on factors such as education, work experience, and language proficiency. Successful applicants enjoy the benefits of living and working in Canada, including access to healthcare, education, and a high quality of life. ",
  },
  {
    id: "ksdh234fojs",
    image: austria,
    title: "Australian Skilled Immigration",
    description:
      "Australia's Skilled Immigration program targets professionals and tradespeople with the qualifications and experience needed in the Australian job market. Through the General Skilled Migration (GSM) program, candidates are assessed on a points-based system considering factors like age, skills, English proficiency, and work experience. Those who qualify can obtain a visa that allows them to live and work in Australia, with pathways to permanent residency. ",
  },
];

export const excluive_immigration = [
  {
    id: "ksdewrhfojs",
    image: lens,
    title: "PNP Finder ",
    description:
      "PNP finder helps you find the Provincial Nominee Program status of all the provinces and territories in Canada which are presently active.",
  },
  {
    id: "ksdh2ewr34fsdjs",
    image: calculator,
    title: "Canada CRS Calculator",
    description:
      "CRS Calculator helps you calculate the Comprehensive Ranking System (CRS) score with the help of our immigration experts. ",
  },
  {
    id: "ksdh2ewr34fsdjs",
    image: calculator,
    title: "Australia PR Calculator",
    description:
      "Australia PR Calculator can help you find your possibilities of obtaining a PR with the help of our immigration experts. ",
  },
];

export const  course_imgs =[
  {
    name:"Engineering",
    img : img
  },
  {
    name:"Arts",
    img : img1
  },
  {
    name:"Health & Science",
    img : img2
  },
  {
    name:"Law & Legal Studies",
    img : img3
  },
  {
    name:"Computer Science",
    img : img4
  },
  {
    name:"Hospitality & Tourism Management",
    img : img5
  },
]

export const blog_list = [
  {
    image:
      "https://images.unsplash.com/photo-1505330622279-bf7d7fc918f4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "10 Tips for Healthy Living",
    description:
      "Explore these useful tips to maintain a healthy lifestyle and improve your overall well-being.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1440778303588-435521a205bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "The Art of Productivity",
    description:
      "Learn effective strategies and techniques to boost your productivity and achieve your goals.",
  },
  {
    image:
      "https://images.unsplash.com/37/tEREUy1vSfuSu8LzTop3_IMG_2538.jpg?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Cooking with Fresh Ingredients",
    description:
      "Discover the joy of cooking with fresh, seasonal ingredients and create delicious meals at home.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1456953180671-730de08edaa7?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Traveling on a Budget",
    description:
      "Explore budget-friendly travel tips and hacks to make the most of your adventures without breaking the bank.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1456324504439-367cee3b3c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dg",
    title: "Mindfulness Meditation Guide",
    description:
      "Embark on a journey of mindfulness and meditation to achieve inner peace and reduce stress in your daily life.",
  },
];

export const monthsArray = [
  { month: "January", id: 1 },
  { month: "February", id: 2 },
  { month: "March", id: 3 },
  { month: "April", id: 4 },
  { month: "May", id: 5 },
  { month: "June", id: 6 },
  { month: "July", id: 7 },
  { month: "August", id: 8 },
  { month: "September", id: 9 },
  { month: "October", id: 10 },
  { month: "November", id: 11 },
  { month: "December", id: 12 },
];

export const guidelines_list = [
  {
    id: "we1",
    count: "1",
    image:
      "https://images.unsplash.com/photo-1614886205583-92fe9eaa38fb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGd1aWRlbGluZXN8ZW58MHx8MHx8fDA%3D",
    title: "Start with meeting our counsellor for free counselling",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tristique ipsum non volutpat pulvinar. Etiam erat erat, sodales sit amet fringilla non, bibendum in libero. Mauris auctor sem vel ex convallis luctus. Vivamus mauris sem, interdum non massa sit amet, vulputate efficitur mi. Praesent tincidunt fermentum urna, sed hendrerit libero consequat quis. Cras viverra imperdiet ante quis luctus. Proin a sodales nisl, ac fringilla neque. Donec mattis ac ligula sed semper.",
  },
  {
    id: "we123",
    count: "2",
    image:
      "https://images.unsplash.com/photo-1584631277142-0ca0cfc76aec?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGd1aWRlbGluZXN8ZW58MHx8MHx8fDA%3D",
    title: "Complete an Initial Assessment",
    description:
      "After the introductory session, the counselor may conduct an initial assessment to gather more information about your background, concerns, and goals. This assessment helps in tailoring the counseling approach to your specific needs. Be open and honest during this process to ensure the counselor has a comprehensive understanding of your situation.",
  },
  {
    id: "we3241",
    count: "3",
    image:
      "https://images.unsplash.com/photo-1499914485622-a88fac536970?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGd1aWRlbGluZXN8ZW58MHx8MHx8fDA%3D",
    title: "Establish Goals and Objectives",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tristique ipsum non volutpat pulvinar. Etiam erat erat, sodales sit amet fringilla non, bibendum in libero. Mauris auctor sem vel ex convallis luctus. Vivamus mauris sem, interdum non massa sit amet, vulputate efficitur mi. Praesent tincidunt fermentum urna, sed hendrerit libero consequat quis. Cras viverra imperdiet ante quis luctus. Proin a sodales nisl, ac fringilla neque. Donec mattis ac ligula sed semper.",
  },
  {
    id: "we1567",
    count: "4",
    image:
      "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Z3VpZGVsaW5lc3xlbnwwfHwwfHx8MA%3D%3D",
    title: "Participate Actively in Counseling Sessions",
    description:
      "Collaborate with your counselor to identify specific goals and objectives for your counseling journey. Clearly articulating what you hope to achieve through counseling helps guide the sessions and provides a roadmap for your progress. These goals can be short-term and long-term, addressing both immediate challenges and broader life improvements.",
  },
  {
    id: "we17",
    count: "5",
    image:
      "https://plus.unsplash.com/premium_photo-1681754370051-4bb901ced4ea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Z3VpZGVsaW5lc3xlbnwwfHwwfHx8MA%3D%3D",
    title: "Regularly Evaluate Progress and Adjust",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tristique ipsum non volutpat pulvinar. Etiam erat erat, sodales sit amet fringilla non, bibendum in libero. Mauris auctor sem vel ex convallis luctus. Vivamus mauris sem, interdum non massa sit amet, vulputate efficitur mi. Praesent tincidunt fermentum urna, sed hendrerit libero consequat quis. Cras viverra imperdiet ante quis luctus. Proin a sodales nisl, ac fringilla neque. Donec mattis ac ligula sed semper.",
  },
];

export const intake_months = [
  {
    id: "01",
    title: "UK January Intake",
    sub: "UK - Age of funds : 28 days",
  },
  {
    id: "0e1",
    title: "UK January Intake",
    sub: "UK - Age of funds : 28 days",
  },
  {
    id: "0r1",
    title: "UK January Intake",
    sub: "UK - Age of funds : 28 days",
  },
  {
    id: "0f1",
    title: "UK January Intake",
    sub: "UK - Age of funds : 28 days",
  },
];
export const step_by_step = [
  {
    id: "0g1",
    title:
      "Start by taking a practice IELTS test to assess your current proficiency level.",
  },
  {
    id: "0g2",
    title: "Set a realistic target band score based on your assessment.",
  },
  {
    id: "0gsdf1",
    title:
      "Develop a detailed daily and weekly study schedule, allocating specific time slots for each section of the IELTS test (Listening, Reading, Writing, Speaking).",
  },
  {
    id: "0gtr1",
    title:
      "Understand the structure of each section and become familiar with the types of questions you'll encounter in the exam.",
  },
  {
    id: "0kijg1",
    title:
      "Choose reliable IELTS study materials, including official practice tests, sample questions, and preparation books.",
  },
  {
    id: "076g1",
    title:
      "Consider online resources, such as interactive platforms and video tutorials.",
  },
  {
    id: "0uhgg1",
    title:
      "Listen to a variety of English accents, including British, American, Australian, and Canadian.",
  },
  {
    id: "0654eghgg1",
    title:
      "Practice listening to different genres like news, podcasts, and academic lectures.",
  },
];

export const things_to_check = [
  {
    id: "#jfgn4",
    title: "Check Your Flight’s Weight Restrictions",
  },
  {
    id: "#jfg2n4",
    title: "Research About Your Location",
  },
  {
    id: "#j3fgn4",
    title: "Label Your Luggage",
  },
  {
    id: "#jf4gn4",
    title: "Focus On Your Carry Bag",
  },
  {
    id: "#jf5gn4",
    title: "Bring Adapters",
  },
  {
    id: "#jf6gn4",
    title: "Pack Medication Wisely",
  },
  {
    id: "#jfg7n4",
    title: "Stay Healthy",
  },
  {
    id: "#jf8gn4",
    title: "Travel Documents",
  },
  {
    id: "#jfg8en4",
    title: "Visit The Government Official Website",
  },
  {
    id: "#jf9gn4",
    title: "Basic Clothing",
  },
];

export const faqArray = [
  {
    question: "What is the purpose of JavaScript?",
    answer:
      "JavaScript is a programming language used for creating interactive and dynamic content on websites. It runs in web browsers and allows developers to add behavior to web pages.",
  },
  {
    question: "How do you declare a variable in JavaScript?",
    answer:
      "You can declare a variable in JavaScript using the 'var', 'let', or 'const' keyword. For example, 'var x = 10;' declares a variable named 'x' with a value of 10.",
  },
  {
    question: "What is the difference between 'let' and 'const' in JavaScript?",
    answer:
      "'let' is used to declare variables that can be reassigned, while 'const' is used for variables whose values should not be changed once assigned. For example, 'let count = 5;' and 'const pi = 3.14;'",
  },
  {
    question: "How do you loop through an array in JavaScript?",
    answer:
      "You can use a 'for' loop or 'forEach' method to iterate through an array in JavaScript. For example, 'for (let i = 0; i < array.length; i++) { console.log(array[i]); }' or 'array.forEach(item => console.log(item));'",
  },
  {
    question: "What is the 'this' keyword in JavaScript?",
    answer:
      "The 'this' keyword refers to the current execution context in JavaScript. Its value depends on how a function is called. In the global scope, 'this' refers to the global object, while inside a function, it can refer to the object the function is a method of.",
  },
  {
    question: "Explain the concept of closures in JavaScript.",
    answer:
      "Closures occur when a function is defined inside another function and has access to the outer function's variables. This allows the inner function to 'remember' the state of the outer function even after the outer function has finished executing.",
  },
];

export const phase_heading = {
  1 : 'Fill in your basic details',
  2 : '',
  3 : 'Current educational details',
  4 : 'Past educational details',
  5 : ''
}

export const introduction_migrate = [
  {
    id: "MC_0d1",
    title: "Eligibility",
    sub_title:
      "Determine your eligibility for programs like Express Entry or Provincial Nominee Programs (PNP).",
  },
  {
    id: "MC_0e1",
    title: "Documents",
    sub_title:
      "Gather ID and Fund proof, language, education and experience certificates.",
  },
  {
    id: "MC_0c1",
    title: "Language Test",
    sub_title:
      "Take IELTS/CELPIP for English or TEF for French to meet language requirements",
  },
  {
    id: "MC_0ew1",
    title: "Create Profile",
    sub_title:
      "Create an online profile and receive a Comprehensive Ranking System (CRS) score.",
  },
  {
    id: "MC_0we1",
    title: "Receive ITA",
    sub_title:
      "Enter the Express Entry pool and await an ITA from IRCC.",
  },
  {
    id: "MC_0qs1",
    title: "Application",
    sub_title:
      "Submit your PR application with medical and police clearances via the IRCC portal.",
  },
  {
    id: "MC_0ad1",
    title: "Fees",
    sub_title:
      "Pay the application processing fee and the right of permanent residence fee.",
  },
  {
    id: "MC_0sds1",
    title: "Medical and Security Checks",
    sub_title:
      "Complete a medical exam and provide police clearance certificates.",
  },
  {
    id: "MC_0sds2",
    title: "Await Decision",
    sub_title:
      "Wait for IRCC to process your application and receive a decision.",
  },
];

export const entry_program_canada = [
  {
    id: "MC_0sdssd1",
    title:
      "Profile Creation: Assessments based on human capital factors, receiving a Comprehensive Ranking System (CRS) score out of 1200.",
  },
  {
    id: "MC_0sdnhgfssd1",
    title:
      "Invitations to Apply (ITA): Highest-scoring candidates receive ITAs for permanent residence in Canada through regular Express Entry draws.",
  },
  {
    id: "MC_0shtdssd1",
    title:
      "Programs Under Express Entry: Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class.",
  },
  {
    id: "MC_0wersdssd1",
    title:
      "Eligibility Criteria: Minimum score of 67 out of 100 based on factors like age, education, language proficiency, work experience, job offer, and adaptability.",
  },
  {
    id: "MC_0sdssdfsd1",
    title:
      "Application Process: Create profile, get ranked, and await ITAs through regular draws.",
  },
  {
    id: "MC_0sdsdfssd1",
    title:
      "CRS Score Calculator: Determined by factors like age, education, work experience, and language proficiency. Provincial nomination adds 600 points.",
  },
  {
    id: "MC_0dsfsdssd1",
    title:
      "Profile Creation: Assessments based on human capital factors, receiving a Comprehensive Ranking System (CRS) score out of 1200.",
  },
];

export const introduction_migrate_australia = [
  {
    id: "MC_0d1",
    title: "Eligibility",
    sub_title:
      "Determine your eligibility through points-based systems like the General Skilled Migration program.",
  },
  {
    id: "MC_0e1",
    title: "Skills",
    sub_title:
      "Get your skills assessed by the relevant assessing authority for your occupation.",
  },
  {
    id: "MC_0ew1",
    title: "Language",
    sub_title:
      "Take an English language test (IELTS, TOEFL, or PTE) to meet language requirements.",
  },
  {
    id: "MC_0we1",
    title: "Submit EOI",
    sub_title:
      "Lodge an EOI through the SkillSelect system, providing details about your skills and experience.",
  },
  {
    id: "MC_0qs1",
    title: "Receive ITA",
    sub_title:
      "Wait for an invitation to apply for a visa based on your EOI and points score.",
  },
  {
    id: "MC_0ad1",
    title: "Application",
    sub_title:
      "Gather documents (ID, education, work, clearance) and submit your PR visa application online.",
  },
  {
    id: "MC_0sds1",
    title: "Clearance",
    sub_title:
      "Complete a health check and obtain police clearance certificates from relevent authorities.",
  },
  {
    id: "MC_0sds3",
    title: "Fees",
    sub_title:
      "Pay the required visa application fees.",
  },
  {
    id: "MC_0sds4",
    title: "Await Decision",
    sub_title:
      "Await a decision from the Department of Home Affairs, may request additional documents.",
  },
  {
    id: "MC_0sds3",
    title: "Receive Visa ",
    sub_title:
      "If approved, receive your visa grant notification and begin preparations to move to Australia.",
  },
];


export const requirements = [
  {
    id: "kxmf",
    title: "Fees",
  },
  {
    id: "kxmf",
    title: "Sponsorship agreement",
  },
  {
    id: "kxmf",
    title: "Medical examinations",
  },
  {
    id: "kxmf",
    title: "Police clearances",
  },
  {
    id: "kxmf",
    title: "Online application submission since September 23, 2022.",
  },
];

export const industryData = [
  {
    value:'full_time',
    name:'Full Time'
  },
  {
    value:'part_time',
    name:'Part Time'
  },{
    value:'contract',
    name:'Contract'
  },{
    value:'remote',
    name:'Remote'
  },{
    value:'hybrid',
    name:'Hybrid'
  },
]
