import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import bg from "../../../../assets/images/form/Enquire.png";
import { accountsConfig } from "../../../../axiosConfig";
import Swal from "sweetalert2";

function Form() {
  const formRef = useRef();
  const [isLoading, setloading] = useState(false);
  const [getCountries, setCountries] = useState([]);
  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  //get countries
  useEffect(() => {
    accountsConfig.get("study/get-countries").then((res) => {
      if (res.data.app_data.StatusCode == 6000) {
        setCountries(res.data.app_data.data);
      } else {
        setCountries([]);
      }
    });
  }, []);

  //submitting the form
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    accountsConfig.post("study/apply-common-enquiry/", formData).then((res) => {
      const { data, StatusCode } = res.data.app_data;

      if (StatusCode === 6000) {
        e.target.reset();
        showSuccessAlert();
      } else {
        showErrorAlert();
      }
    });
  };
  return (
    <Container>
      <Wrapper>
        <Content>
          <Left>
            <img src={bg} alt="image" />
            <ContentDiv>
              <Titles>
                Excited to begin your
                <br /> educational journey?
              </Titles>
              <Help> We're here to help!</Help>
              <ParaGraph>
                Simply fill out the enquiry form below,
                <br /> and our team will be thrilled to assist you with
                <br /> all the information you need to get started.
              </ParaGraph>
            </ContentDiv>
          </Left>
          <Right>
            <FormCover>
              <Title>Medcity can Help You</Title>
              <FormSection onSubmit={(e) => handleFormSubmit(e)} ref={formRef}>
                <Cover>
                  <CoverInput className="first">
                    <Label>First Name *</Label>
                    <Input
                      type="text"
                      placeholder="First name"
                      name="first_name"
                    />
                  </CoverInput>
                  <CoverInput className="sec">
                    <Label>Last Name *</Label>
                    <Input
                      type="text"
                      placeholder="Last name"
                      name="last_name"
                    />
                  </CoverInput>
                </Cover>
                <Cover>
                  <CoverInput className="first">
                    <Label>Email *</Label>
                    <Input type="email" placeholder="Email" name="email" />
                  </CoverInput>
                  <CoverInput className="sec">
                    <Label>Number *</Label>
                    <Input type="number" placeholder="Phone" name="number" />
                  </CoverInput>
                </Cover>
                <CoverInput>
                  <Label>Study Destination *</Label>
                  <Select name="study_destination">
                    <option selected value="">
                      --Destination--
                    </option>
                    {getCountries.map((obj, index) => (
                      <option value={obj.id} index={index}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                </CoverInput>
                <Cover>
                  <CoverInput className="intake">
                    <Label>Intake *</Label>
                    <CoverSelect>
                      <Select name="intake">
                        <option selected value="">
                          --Select Month--
                        </option>
                        <option value="janaury">Janaury</option>
                        <option value="february">February</option>
                        <option value="march">March</option>
                        <option value="april">April</option>
                        <option value="may">May</option>
                        <option value="june">June</option>
                        <option value="july">July</option>
                        <option value="august">August</option>
                        <option value="september">September</option>
                        <option value="october">October</option>
                        <option value="november">November</option>
                        <option value="december">December</option>
                      </Select>
                      <Input
                        type="number" // Encourage numeric input on most devices
                        id="yearInput"
                        maxlength="4"
                        pattern="\d{4}"
                        title="Enter a valid 4-digit year"
                        placeholder="YYYY"
                        onInput={(event) => {
                          const input = event.target;
                          if (input.value.length > 4) {
                            input.value = input.value.slice(0, 4); // Truncate
                          }
                        }}
                        name="year"
                      />
                    </CoverSelect>
                  </CoverInput>
                </Cover>
                <CoverInput>
                  <Label>Qualification *</Label>
                  <Select name="qualification">
                    <Label>Study Destination</Label>
                    <option selected value="">
                      --Degree--
                    </option>
                    <option value="graduate">Graduate</option>
                    <option value="bachelor">Bachelor</option>
                    <option value="master">Master</option>
                    <option value="phd">Phd</option>
                  </Select>
                </CoverInput>
                <CoverInput>
                  <Label>Branch *</Label>
                  <Input type="text" placeholder="--Branch--" name="branch" />
                </CoverInput>
                <Button>Submit</Button>
              </FormSection>
            </FormCover>
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Form;

const Container = styled.div`
  /* padding: 80px 0; */
  background-color: #f7f7f7;
  @media all and (max-width: 1080px) {
    padding: 40px;
  }
`;
const Wrapper = styled.div``;
const ContentDiv = styled.div`
  color: #fff;
  position: absolute;
  transform: translate(40%);
  top: 30%;
  @media all and (max-width: 1440px) {
    transform: translate(30%);
    top: 20%;
  }
`;
const Titles = styled.h2`
  font-family: "ubuntu-light";
  font-size: 45px;
  margin-bottom: 50px;
`;
const ParaGraph = styled.h4`
  line-height: 28px;
  font-size: 18px;
  font-family: "ubuntu-light";
  letter-spacing: 2px;
  margin-top: 40px;
`;
const Help = styled.h2`
  font-family: "ubuntu-regular";
  font-size: 45px;
  padding-bottom: 60px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 150px;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
`;
const FormCover = styled.div`
  width: 70%;
  margin-left: 70px;
  @media all and (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
  }
`;
const CoverSelect = styled.div`
  display: flex;
`;
const Left = styled.div`
  width: 50%;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const Right = styled.div`
  width: 50%;
  @media all and (max-width: 1080px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1080px) {
    justify-content: center;
  }
`;
const Title = styled.h2`
  text-align: left;
  width: 60%;
  margin-bottom: 20px;
  /* @media all and (max-width: 1440px) {
    width: 50%;
  } */
`;
const FormSection = styled.form`
  /* @media all and (max-width: 1440px) {
    width: 50%;
  } */
`;
const Cover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Input = styled.input`
  width: 100%;
  box-sizing: border-box;

  height: 50px;
  /* background: #f8f4f4; */
  outline: none;
  border-radius: 5px;
  border: 1px solid #00b1c3;
  outline-style: solid;
  padding-left: 20px;
  margin-bottom: 30px;
  margin-right: 20px;
  outline-color: #00b1c3;
  outline-width: 1px;
  &:focus {
    outline-color: var(--darkblue);
  }
  &:nth-child(even) {
    margin-right: 0;
  }
  @media all and (max-width: 1680px) {
    height: 40px;
    margin-bottom: 20px;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Select = styled.select`
  border: 2px solid #00b1c3;
  margin-bottom: 30px;
  padding-left: 20px;
  height: 50px;
  background: #fff;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
  color: #8e8e8e;
  outline-color: #00b1c3;
  &:focus {
    outline-color: var(--darkblue);
  }
  &:nth-child(even) {
    margin-right: 0;
  }
  option {
    color: grey;
  }
  @media all and (max-width: 1680px) {
    height: 40px;
    margin-bottom: 20px;
  }
`;
const Button = styled.button`
  background: #00aec0;
  height: 40px;
  border: none;
  width: 150px;
  border-radius: 5px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const CoverInput = styled.div`
  width: 100%;
  &.first {
    margin-right: 20px;
    width: 48%;
  }
  &.sec {
    width: 48%;
  }
  &.intake {
  }
`;
const Label = styled.div`
  margin-bottom: 10px;
`;
