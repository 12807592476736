import React, { useEffect, useState, useRef } from "react";
//image
import thumb1 from "../../../../assets/images/testimonials/thumb1.png";
import thumb2 from "../../../../assets/images/testimonials/thumb2.png";
import thumb3 from "../../../../assets/images/testimonials/thumb3.png";
import styled from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import { accountsConfig } from "../../../../axiosConfig";
import { Image } from "antd";
import Footer from "../includes/Footer";

export default function Testimonials() {
  const [getData, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    accountsConfig.get("study/list-testimonial/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);

  useEffect(() => {
    if (!isModalOpen && videoRef.current) {
      videoRef.current.pause();
      setCurrentVideo(null);
    }
  }, [isModalOpen]);

  const handleImageClick = (videoSrc) => {
    setCurrentVideo(videoSrc);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <Wrapper className="wrapper">
        <Content>
          <Title>Testimonials</Title>
          <Testimonial>
            {getData.map((item, index) => (
              <TextBar key={index} type={index % 2}>
                <ImageContainer
                  type={index % 2}
                  onClick={() => handleImageClick(item.video)}
                >
                  <Image
                    width={450}
                    src={item.thumbnail}
                    preview={false}
                  />
                </ImageContainer>
                <Detail>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </Detail>
              </TextBar>
            ))}
          </Testimonial>
        </Content>
      </Wrapper>
      <Footer />
      {isModalOpen && (
        <Modal onClose={handleModalClose}>
          <Video ref={videoRef} controls autoPlay src={currentVideo} />
        </Modal>
      )}
    </Container>
  );
}

const Modal = ({ onClose, children }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

const Container = styled.div`
  background-color: #f8f8f8;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  padding-top: 150px;
  @media (max-width: 480px) {
    padding-top: 120px;
  }
`;
const Title = styled.h1`
  color: var(--blue);
  text-align: center;
  margin-bottom: 20px;
`;
const Testimonial = styled.div`
  margin-top: 80px;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;
const TextBar = styled.div`
  display: flex;
  background-color: #fff;
  padding: 30px;
  flex-direction: ${({ type }) => (type ? "row-reverse" : "row")};
  align-items: center;
  height: 150px;
  margin-bottom: 100px;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 1080px) {
    display: block;
    height: unset;
  }
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
`;
const ImageContainer = styled.div`
  width: 40%;
  position: absolute;
  ${({ type }) =>
    type !== 0
      ? `
      right: 70px;
      left: unset;
    `
      : `
      left: 70px;
      right: unset;
    `}
  top: -30px;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 1080px) {
    position: unset;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const Detail = styled.div`
  width: 48%;
  line-height: 32px;
  font-family: "ubuntu-italic";
  color: var(--blue);
  font-size: 20px;
  @media (max-width: 1080px) {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
`;

const Video = styled.video`
  height: 95vh;
  @media (max-width: 768px) {
    height: 70vh;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  /* padding: 20px; */
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 11111;
  background-color: #ffffff97;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  line-height: 0px;
`;

