import React, { useState } from "react";
import styled from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";

import img from "../../../../assets/images/loan-assitance/bg.png";
import { useEffect } from "react";
import { accountsConfig } from "../../../../axiosConfig";

function LoanInfo() {
 const [loanInfo, setloanInfo] = useState({});

 useEffect(() => {
  accountsConfig.get('study/list-educational-assistance/')
  .then((response)=>{
    const { StatusCode , data } = response.data.app_data;
    if(StatusCode === 6000){
      setloanInfo(data)
    }
  })
 },[])

  return (
    <Section>
      <HeaderEdit backgroundCl={"#1F93B0"} />
      <Container className="wrapper">
        <Bg>
          <img src={img} alt="" />
        </Bg>
        <Box>
          <Heading>{loanInfo?.title}</Heading>
          <Para dangerouslySetInnerHTML={{__html:loanInfo?.description}}/>
        </Box>
      </Container>
      <Footer/>
    </Section>
  );
}

export default LoanInfo;

const Section = styled.section`
  padding-top: 160px;
  background-color: #f8f8f8;

  @media screen and (max-width: 600px) {
    padding-top: 73px;
  }
`;

const Container = styled.div`
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`;

const Bg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;

    /* @media screen and (max-width: 580px) {
      object-fit: contain;
    } */
  }
`;

const Box = styled.div`
  padding: 80px;
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  top: -80px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    width: 85%;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 40px;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    position: unset;
    padding: 25px;
  }
`;

const Heading = styled.h1`
  color: #1f93b0;
  font-size: 50px;
  /* font-weight: unset; */
  text-align: center;
  font-style: normal;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 580px) {
    font-size: 32px;
    text-align: center;
  }
`;

const Para = styled.p`
  color: #2b2b2b;
  text-align: justify;
  font-size: 15px;
  font-style: normal;
  line-height: 22px;
  font-weight: unset;

  a{
    color: #19a2bb;
  }
`;
